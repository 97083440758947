/** @format */

import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { formatDate } from "../../tools/format-date";

/**
 *
 *  @params none
 *
 * This component retuns the html which the Datatables will add a "Click" event to. When clicked, the user will download a CSV file containing all of their inventory.
 */

export const DownloadButton = ({ activities }) => {
  const [downloadData, setDownloadData] = useState([
    {
      Organization: "",
      Diversity: [],
      Interaction: [],
      Created: null,
      Updated: null,
      Author: "",
      Location: "",
      Title: "",
      Content: "",
    },
  ]);

  useEffect(() => {
    try {
      if (
        activities.companyActivities &&
        activities.companyActivities.length > 0
      ) {
        const normalizedDownloadData = activities.companyActivities.map(
          (activity) => ({
            Organization: activity.type,
            Diversity: activity.categories,
            Interaction: activity.interaction,
            Created: formatDate(activity.createdAt),
            Updated: formatDate(activity.updatedAt),
            Author: activity.author,
            Location: activity.location,
            Title: activity.title,
            Content: activity.contentBody,
            Referrals: activity.numberOfReferals,
            Hires: activity.numberOfHires,
            Effectiveness: activity.effectiveness,
          })
        );
        setDownloadData(normalizedDownloadData);
      }
    } catch (error) {
      console.log(error);
    }
  }, [activities.companyActivities]);

  return (
    <div>
      <button
        className="btn datatable-csv p-1 "
        type="button"
        id="ExportReporttoExcel"
        style={{
          backgroundColor: "transparent",
          color: "#599EE4",
          border: "none",
        }}
      >
        <i className="far fa-download mr-1 text-primary"></i>
        <CSVLink data={downloadData} filename={"Activities.csv"}>
          Download All
        </CSVLink>
      </button>
    </div>
  );
};

export default React.memo(DownloadButton);
