/** @format */

export const displayCreateLoading = () => {
  return (
    <div className="d-flex align-items-center py-3 ">
      <div
        className=" spinner-border spinner-border-sm text-primary"
        role="status"
      ></div>
    </div>
  );
};
export const displayCreateSuccess = () => {
  return (
    <div className="d-flex align-items-center py-3 ">
      <i className="fa fa-2x fa-check-circle text-success"></i>
      <span className="ml-2 text-success">Success!</span>
    </div>
  );
};
export const displayCreateFailure = () => {
  return (
    <div className="d-flex align-items-center py-3">
      <i className="fa fa-2x fa-times text-danger"></i>
      <span className="ml-2 text-danger ">Failed!</span>
    </div>
  );
};
export const displayEditLoading = () => {
  return (
    <div className="d-flex align-items-center py-3 ">
      <div
        className=" spinner-border spinner-border-sm text-primary"
        role="status"
      ></div>
    </div>
  );
};
export const displayEditSuccess = () => {
  return (
    <div className="d-flex align-items-center py-3 ">
      <i className="fa fa-2x fa-check-circle text-success"></i>
      <span className="ml-2 text-success">Success!</span>
    </div>
  );
};
export const displayEditFailure = () => {
  return (
    <div className="d-flex align-items-center py-3">
      <i className="fa fa-2x fa-times text-danger"></i>
      <span className="ml-2 text-danger ">Failed!</span>
    </div>
  );
};
