/** @format */

import React, { useCallback } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { failureDeleteToaster, successDeleteToaster } from "../modal-toasters";

function ConfirmDelete({
  show,
  handleClose,
  deleteNote,
  deleteRowId,
  fetchActivities,
  user,
  resetTable,
  clearSelectedRows,
}) {
  const clearSelections = useCallback(() => {
    /* This function will set clearSelectedRows state variable to true which will trigger 
    Datatable to flush selected rows from cache. resetTable() will bring clearSelectedRows back to original state */
    clearSelectedRows();
    resetTable();
  }, [resetTable, clearSelectedRows]);

  const submitDelete = useCallback(async () => {
    const deletedNote = await deleteNote({
      id: deleteRowId,
      token: user.jtToken,
      company_id: user.company.companyId,
    });
    if (deletedNote.status === 1) {
      toast(successDeleteToaster, {
        position: toast.POSITION.TOP_CENTER,
      });
      if (user.company.companyId) {
        const deletedNote = await fetchActivities(
          user.company.companyId,
          user.jtToken
        );
        if (deletedNote) clearSelections();
      }
    } else {
      toast(failureDeleteToaster, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    handleClose();
  }, [
    deleteNote,
    deleteRowId,
    handleClose,
    fetchActivities,
    user.company.companyId,
    user.jtToken,
    clearSelections,
  ]);
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Delete Activity?</Modal.Title>
        <button
          type="button"
          className="close opacity-10"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => handleClose()}
        >
          <span aria-hidden="true">
            <i className="fal fa-times text-15 text-dark-fill"></i>
          </span>
        </button>
      </Modal.Header>
      <Modal.Body>You will not be able to recover it</Modal.Body>
      <Modal.Footer>
        <Button
          style={{
            backgroundColor: "white",
            color: "#599EE4",
            border: "none",
          }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button variant="primary" onClick={submitDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmDelete;
