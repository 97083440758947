/** @format */

import React from "react";
import Logo from "../../images/logo/activitieslogo.svg";
import HamburgerMenu from "../hamburger-menu/index";
// import { useMediaQuery } from 'react-responsive'

/**
 *
 @param {function} toggleAddActivityModal
 *
 * This component returns the html necessary for the title portion of the application. It takes a prop from the redux store to toggle the modal for adding the activity. This action was lifted to the redux store because the toggle button exists in this component but the modal that needs that event to open is found in the ActivityTable component. Therefore, there was no way to pass these props to the modal from a parent to child relationship.
 */

const Title = ({ toggleAddActivityModal }) => {
  return (
    <div id="jt-app-header" className="card-header bg-transparent">
      <div className="row">
        <div className="col-12 d-flex justify-content-between flex-column flex-md-row">
          <div className="col-sm-6 p-0 col-md-6 d-flex align-items-center">
            <img src={Logo} alt="logo" />
            <span className="ml-2 mt-2 h5">OFCCP Activities</span>
          </div>
          <div className="col-sm-6 p-0 flex-wrap d-flex justify-content-md-end justify-content-between">
            <button
                className="btn btn-primary h-lg-100 mt-4 mt-lg-0"
                onClick={toggleAddActivityModal}
              >
                Add Activity
              </button>  
            <HamburgerMenu />
          </div>
        </div>
      </div>
  </div>
);
};
export default React.memo(Title);
