/** @format */

import {
  FETCH_ACTIVITIES_FAILURE,
  FETCH_ACTIVITIES_REQUEST,
  FETCH_ACTIVITIES_SUCCESS,
  EXPAND_ALL_ACTIVITIES,
  COLLAPSE_ALL_ACTIVITIES,
} from "./FetchActivitiesTypes";

export const fetchActivitiesRequest = () => {
  return {
    type: FETCH_ACTIVITIES_REQUEST,
  };
};

export const fetchActivitiesSuccess = (response) => {
  return {
    type: FETCH_ACTIVITIES_SUCCESS,
    payload: response,
  };
};

export const fetchActivitiesFailure = (response) => {
  return {
    type: FETCH_ACTIVITIES_FAILURE,
    payload: response,
  };
};
export const expandAll = () => {
  return {
    type: EXPAND_ALL_ACTIVITIES,
  };
};
export const collapseAll = () => {
  return {
    type: COLLAPSE_ALL_ACTIVITIES,
  };
};

export const fetchActivities = (company_id, token) => {

  return async (dispatch) => {
    try {
      dispatch(fetchActivitiesRequest());
      const activityPromise = await fetch(
        process.env.REACT_APP_BACKEND_URL + "/getNotes",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            company_id,
          }),
        }
      );
      if (activityPromise.status === 200) {
        const activityPromiseJson = await activityPromise.json();
        dispatch(fetchActivitiesSuccess(activityPromiseJson));
        return true;
      } else {
        throw new Error("Failed to fetch user's activities.");
      }
    } catch (error) {
      console.log(error);
      dispatch(fetchActivitiesFailure(error.message));
      return false;
    }
  };
};
// export const mockAPIFetchInventory = () => {
//   return (dispatch) => {
//     dispatch(fetchInventoryData());
//     getUserInventory()
//       .then((res) => {
//         dispatch(fetchInventorySuccess(res));
//       })
//       .catch((err) => dispatch(fetchInventoryFailure(err)));
//   };
// };
