/** @format */

import {
  FETCH_INVENTORY_FAILURE,
  FETCH_INVENTORY_REQUEST,
  FETCH_INVENTORY_SUCCESS,
} from "./FetchUserInventoryTypes";

export const fetchInventoryData = () => {
  return {
    type: FETCH_INVENTORY_REQUEST,
  };
};

export const fetchInventorySuccess = (response) => {
  return {
    type: FETCH_INVENTORY_SUCCESS,
    payload: response,
  };
};

export const fetchInventoryFailure = (response) => {
  return {
    type: FETCH_INVENTORY_FAILURE,
    payload: response,
  };
};

export const fetchInventory = (user_id, companyId, token) => {
  let company_id = companyId;
  return async (dispatch) => {
    try {
      dispatch(fetchInventoryData());
      const inventoryResponse = await fetch(
        process.env.REACT_APP_BACKEND_URL + "/grabUserInventory",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },

          //make sure to serialize your JSON body
          body: JSON.stringify({ user_id, company_id }),
        }
      );
      if (inventoryResponse.status === 200) {
        const inventoryPromiseJson = await inventoryResponse.json();
        dispatch(fetchInventorySuccess(inventoryPromiseJson));
        return true;
      } else {
        throw new Error("Failed to fetch user's activities.");
      }
    } catch (error) {
      console.log(error);
      dispatch(fetchInventoryFailure(error.message));
      return false;
    }
  };
};
// export const mockAPIFetchInventory = () => {
//   return (dispatch) => {
//     dispatch(fetchInventoryData());
//     getUserInventory()
//       .then((res) => {
//         dispatch(fetchInventorySuccess(res));
//       })
//       .catch((err) => dispatch(fetchInventoryFailure(err)));
//   };
// };
