/** @format */

import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useLayoutEffect,
} from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { connect } from "react-redux";
import GoogleApi from "../google-api";
import { editNote } from "../../API/Api";
import { handleFlushSelectRowData } from "../../redux/EditOrganization/SelectedRowForEditActions";
import EditActivityModalOrgNameSwapper from "./org-name-swapper";
import { fetchActivities } from "../../redux/FetchActivities/FetchActivitiesActions";
import {
  displayEditLoading,
  displayEditFailure,
  displayEditSuccess,
} from "../modal-responses";
import { toast } from "react-toastify";
import { successEditToaster, failureEditToaster } from "../modal-toasters";
import DOMPurify from "dompurify";
// import toast from "react-hot-toast";

/**
 * @param {function} onHide
 * @param {function} postActivity
 * @param {object} modal
 * @param {boolean} show
 *
 * This component returns the modal that the user interfaces with when trying to edit an activity. The activity will be changed in MongoDB.
 */

const categoryValues = [
  "African American",
  "Asian",
  "Hispanic Or Latino",
  "Native American",
  "Native Hawaiian Or Pacific Islander",
  "LGBTQ",
  "Female",
  "Older/Experienced",
  "Collegiate/Entry Level",
  "Disability",
  "Veteran",
  "Diversity-Other",
];

const interactionValues = [
  "In-Person Meeting",
  "Virtual Meeting",
  "Career Fair",
  "Virtual Fair",
  "Email",
  "Phone Call",
  "Facility Tour",
  "Sponsorship",
  "Other Recruiting Event",
  "Other",
];

export const EditActivityModal = ({
  show,
  onHide,
  user,
  inventory,
  editData,
  flushEditData,
  fetchActivities,
}) => {
  // refs
  const formRef = useRef();
  const categorySelect = useRef();
  const interactionSelect = useRef();
  const orgNameSelect = useRef();

  const purify = new DOMPurify();

  // state variables
  const [loading, setLoading] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState("empty");
  const [editModalRowData, setEditModalRowData] = useState({
    categories: [],
    interaction: [],
    type: "",
    location: "",
    title: "",
    contentBody: "",
    company_id: null,
    author: "",
    numberOfHires: 0,
    numberOfReferals: 0,
    effectiveness: "",
  });

  // Set the row data passed from the onclick of edit icon to the state to be used in the modal

  useEffect(() => {
    if (editData) {
      setEditModalRowData(editData);
    }
  }, [editData, setEditModalRowData]);

  useEffect(() => {
    if (editModalRowData.categories) {
      window
        .$(categorySelect.current)
        .selectpicker("val", editModalRowData.categories);

      window
        .$(interactionSelect.current)
        .selectpicker("val", editModalRowData.interaction);
    }
  }, [categorySelect, editModalRowData]);

  // Add the styles to the dropdowns

  useLayoutEffect(() => {
    if (show) {
      window.$(".edit-categories-select").selectpicker({
        dropupAuto: false,
        size: 8,
        styleBase: "form-control",
        style: "",
      });
      window.$(".edit-interaction-select").selectpicker({
        dropupAuto: false,
        size: 8,
        styleBase: "form-control",
        style: "",
      });
      window.$(".edit-orgName-select").selectpicker({
        dropupAuto: false,
        size: 8,
        styleBase: "form-control",
        style: "",
      });
    } else {
      setTimeout(() => {
        window.$(".edit-categories-select").selectpicker("destroy");
        window.$(".edit-interaction-select").selectpicker("destroy");
        window.$(".edit-orgName-select").selectpicker("destroy");
      }, 150);
    }
  }, [show]);

  useLayoutEffect(() => {
    try {
      if (categorySelect.current) {
        if (
          editModalRowData.categories &&
          editModalRowData.categories.length > 0
        ) {
          window.$(".edit-categories-select").selectpicker(
            "val",
            editModalRowData.categories.map((item) => item.value)
          );
        }
      }

      if (interactionSelect.current) {
        if (
          editModalRowData.interaction &&
          editModalRowData.interaction.length > 0
        ) {
          window.$(".edit-interaction-select").selectpicker(
            "val",
            editModalRowData.interaction.map((item) => item.value)
          );
        }
      }
      if (orgNameSelect.current) {
        if (editModalRowData.type) {
          window
            .$(".edit-orgName-select")
            .selectpicker("val", editModalRowData.type);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [
    editModalRowData.interaction,
    editModalRowData.type,
    editModalRowData.categories,
  ]);

  const checkSelectValidity = useCallback(() => {
    categorySelect.current.classList.add("was-validated");
    try {
      categorySelect.current.classList.add("was-validated");

      if (interactionSelect.current) {
        if (
          editModalRowData.categories &&
          editModalRowData.categories.length > 0
        ) {
          categorySelect.current.setCustomValidity("");
        } else {
          categorySelect.current.setCustomValidity("invalid");
        }
      }

      if (categorySelect.current) {
        if (
          editModalRowData.interaction &&
          editModalRowData.interaction.length > 0
        ) {
          interactionSelect.current.setCustomValidity("");
        } else {
          interactionSelect.current.setCustomValidity("invalid");
        }
      }

      if (orgNameSelect.current) {
        if (editModalRowData.type && editModalRowData.type.length > 0) {
          orgNameSelect.current.setCustomValidity("");
        } else {
          orgNameSelect.current.setCustomValidity("invalid");
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [
    editModalRowData.categories,
    editModalRowData.interaction,
    editModalRowData.type,
  ]);

  const handleResponse = useCallback((response) => {
    /**
     * ! The Submission statuses were commented out along with setTimeouts upon request from the PM but can be turned out to have pending states on the post requests.
     */
    if (response.status === 1) {
      // setSubmissionStatus("success");
      displayMsg(successEditToaster);
      // setTimeout(() => {
      // }, 500);
    } else if (response.status === 0) {
      // setSubmissionStatus("error");
      displayMsg(failureEditToaster);
      // setTimeout(() => {
      // }, 500);
    }
  }, []);

  const displaySubmissionStatus = useCallback(() => {
    if (submissionStatus === "loading") {
      return displayEditLoading();
    } else if (submissionStatus === "success") {
      return displayEditSuccess();
    } else if (submissionStatus === "error") {
      return displayEditFailure();
    } else return;
  }, [submissionStatus]);

  const displayMsg = (customToast) => {
    toast(customToast, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      formRef.current.classList.add("was-validated");
      checkSelectValidity();
      if (formRef.current.checkValidity()) {
        // setSubmissionStatus("loading");
        setLoading(true);
        const editActivity = await editNote({
          payload: editModalRowData,
          id: editModalRowData._id,
          token: user.user.jtToken,
        });
        handleResponse(editActivity);
        onHide();
        setTimeout(() => {
          fetchActivities(user.user.company.companyId, user.user.jtToken);
          // setSubmissionStatus("empty");
          setLoading(false);
        }, 1000);
      }
    },
    [
      editModalRowData,
      checkSelectValidity,
      onHide,
      fetchActivities,
      user.user,
      handleResponse,
    ]
  );

  return (
    <Modal
      show={show}
      onHide={() => {
        flushEditData();
        onHide();
      }}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
    >
      <Modal.Header className="bg-white">
        <Modal.Title id="contained-modal-title-vcenter">
          Edit Activity
        </Modal.Title>
        <button
          type="button"
          className="close opacity-10"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onHide}
        >
          <span aria-hidden="true">
            <i className="fal fa-times text-15 text-dark-fill"></i>
          </span>
        </button>
      </Modal.Header>

      <Form className="" noValidate ref={formRef} onSubmit={handleSubmit}>
        <Modal.Body>
          {displaySubmissionStatus()}

          <EditActivityModalOrgNameSwapper
            editModalRowData={editModalRowData}
            setEditModalRowData={setEditModalRowData}
            inventory={inventory}
            orgNameSelect={orgNameSelect}
          />

          <Form.Group className="mb-3">
            <Form.Label>
              Diversity Categories<span className="text-danger">*</span>
            </Form.Label>
            <select
              title="Select Diversity Categories"
              ref={categorySelect}
              className="edit-categories-select form-control bootstrap-select selectpicker"
              name="categories"
              multiple
              required
              onChange={() => {
                setEditModalRowData({
                  ...editModalRowData,
                  categories: window
                    .$(categorySelect.current)
                    .selectpicker("val"),
                });
              }}
            >
              {categoryValues.map((option, i) => (
                <option key={i} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <p className="valid-feedback">Looks good!</p>
            <p className="invalid-feedback">
              Diversity category is required is required
            </p>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>
              Interaction Type<span className="text-danger">*</span>
            </Form.Label>
            <select
              title="Select Interactions"
              className=" edit-interaction-select form-control bootstrap-select selectpicker"
              ref={interactionSelect}
              multiple
              required
              name="interaction"
              onChange={() => {
                setEditModalRowData({
                  ...editModalRowData,
                  interaction: window
                    .$(interactionSelect.current)
                    .selectpicker("val"),
                });
              }}
            >
              {interactionValues.map((option, i) => (
                <option key={i} value={option}>
                  {option}
                </option>
              ))}
            </select>
            {/* <p className="valid-feedback">Looks good!</p> */}
            <p className="invalid-feedback">Interaction type is required</p>
          </Form.Group>

          <GoogleApi
            activityModal={editModalRowData}
            setActivityModal={setEditModalRowData}
          />

          <Form.Group className="mb-3">
            <Form.Label>Number of Referrals</Form.Label>
            <Form.Control
              value={editModalRowData.numberOfReferals}
              onChange={(e) => {
                setEditModalRowData({
                  ...editModalRowData,
                  numberOfReferals: e.target.value,
                });
              }}
              type="number"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Number of Hires</Form.Label>
            <Form.Control
              value={editModalRowData.numberOfHires}
              onChange={(e) =>
                setEditModalRowData({
                  ...editModalRowData,
                  numberOfHires: e.target.value,
                })
              }
              type="number"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Effectiveness of the Outreach</Form.Label>
            <Form.Control
              value={editModalRowData.effectiveness}
              onChange={(e) => {
                const sanitizedInput = purify.sanitize(e.target.value);
                setEditModalRowData({
                  ...editModalRowData,
                  effectiveness: sanitizedInput,
                });
              }}
              placeholder="Describe the effectiveness of the outreach"
              as="textarea"
              type="text"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Activity Title</Form.Label>
            <Form.Control
              type="text"
              name="title"
              value={editModalRowData.title}
              onChange={(e) => {
                const sanitizedInput = purify.sanitize(e.target.value);

                setEditModalRowData({
                  ...editModalRowData,
                  title: sanitizedInput,
                });
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Activity Details</Form.Label>
            <Form.Control
              name="body"
              as="textarea"
              rows={4}
              value={editModalRowData.contentBody}
              onChange={(e) => {
                const sanitizedInput = purify.sanitize(e.target.value);
                setEditModalRowData({
                  ...editModalRowData,
                  contentBody: sanitizedInput,
                });
              }}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="justify-content-start justify-content-lg-end">
          <Button
            className="btn"
            style={{
              backgroundColor: "white",
              color: "#599EE4",
              border: "none",
            }}
            type="button"
            onClick={onHide}
          >
            Cancel
          </Button>
          <Button type="submit" className="submit__button">
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    inventory: state.userInventory,
    editData: state.editModalData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    flushEditData: () => dispatch(handleFlushSelectRowData()),
    fetchActivities: (company_id, token) =>
      dispatch(fetchActivities(company_id, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditActivityModal);
