/** @format */

import React from "react";
import { Form } from "react-bootstrap";
import DOMPurify from "dompurify";

function OrganizationNameInput({
  setActivityModal,
  setSelectFromInventory,
  activityModal,
}) {
  const purify = new DOMPurify();

  return (
    <Form.Group className="mb-3">
      <Form.Label>
        Organization<span className="text-danger">*</span>
      </Form.Label>
      <Form.Control
        name="type"
        type="text"
        value={activityModal.organizationName}
        onChange={(e) => {
          const sanitizedInput = purify.sanitize(e.target.value);
          setActivityModal((state) => ({
            ...state,
            organizationName: sanitizedInput,
          }));
        }}
        required
      />
      <button
        className="btn btn-link p-0 mt-1"
        onClick={() => setSelectFromInventory(true)}
      >
        Search My Organizations
      </button>
    </Form.Group>
  );
}

export default OrganizationNameInput;
