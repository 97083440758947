/** @format */

import { LOADING, LOGIN, LOGOUT, VALIDATED_USER_ID } from "./userTokenTypes";

const initialState = {
  user: {
    user_id: undefined,
    company: {
      companyId: undefined,
    },
  },
  isLogged: false,
  isLoading: false,
  hasChecked: false,
  jtToken: "",
  isLogout: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case LOGIN:
      return {
        ...state,
        user: action.payload.user,
        jtToken: action.payload.jtToken,
        isLogged: true,
        isLoading: false,
        hasChecked: true,
        isLogout: false,
      };
    case LOGOUT:
      return {
        ...state,
        isLogged: false,
        isLoading: false,
        hasChecked: true,
        isLogout: true,
      };
    case VALIDATED_USER_ID:
      return {
        ...state,
        user_id: action.payload.data.user_id,
      };
    default:
      return state;
  }
};

export default reducer;
