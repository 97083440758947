/** @format */
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route } from "react-router-dom";
import store from "./store";
import Validate from "./components/validate-user";
import { datadogRum } from "@datadog/browser-rum";

datadogRum.init({
  applicationId: process.env.REACT_APP_applicationId || "",
  clientToken: process.env.REACT_APP_clientToken || "",
  site: "datadoghq.com",
  service: "activities-ui",
  env: process.env.REACT_APP_env,
  // Specify a version number to identify the deployed version of your application in Datadog
  version: process.env.REACT_APP_DD_VERSION || '1.0.0',
  sessionReplaySampleRate: 20,
  sessionSampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
});

datadogRum.startSessionReplayRecording();

// Link the user information in DataDog to the logged in user
window.dispatchEvent(new CustomEvent("requestuserinfo:jt:navbar"));
window.addEventListener("userinfo:jt:navbar", event => {
  datadogRum.setUser({
    id: event.detail.user_id,
    name: event.detail.company.companyName
  })
});

function App() {
  console.log("version 1.0.1");
  // Root component.
  // use to handle HomePage and fallback 404 page.

  // 9/27 revision - removed route to Homepage and Validate renders HomePage after

  return (
    <Provider store={store}>
      <>
        <Router>
          <Route exact path="/" component={Validate} />
        </Router>
      </>
    </Provider>
  );
}

export default App;
