/** @format */

import React, { useState } from "react";

function GenerateCollapsibleCategories({ obj, i }) {
  const [innerText, setInnerText] = useState(false);

  return (
    <div className="position-relative t-1">
      <ul className="p-0 my-0">
        <li style={{ listStyle: "none" }}>{obj.categories[0]}</li>
        <span className="collapse" id={`td-category-${i}`}>
          {obj.categories.slice(1).map((category) => {
            return <li>{category}</li>;
          })}
        </span>
      </ul>
      <p>
        <a
          href={() => false}
          data-toggle="collapse"
          data-target={`#td-category-${i}`}
          className="collapsed"
          aria-expanded="false"
          onClick={() => setInnerText(!innerText)}
        >
          {innerText ? "Show Less" : "Show More"}
        </a>
      </p>
    </div>
  );
}

export default GenerateCollapsibleCategories;
