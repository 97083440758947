/** @format */

export const successCreateToaster = ({ closeToast, toastProps }) => (
  <div className="d-flex align-items-center">
    <i class="fa fa-2x fa-check-circle text-success"></i>
    <p class="mb-0 w-100 pl-5">Activity Submitted Successfully</p>
  </div>
);
export const failureCreateToaster = ({ closeToast, toastProps }) => (
  <div className="d-flex align-items-center">
    <i class="fa fa-2x fa-times text-danger"></i>
    <p class="mb-0 w-100 pl-5">Activity Creation Failed</p>
  </div>
);
export const successEditToaster = ({ closeToast, toastProps }) => (
  <div className="d-flex align-items-center">
    <i class="fa fa-2x fa-check-circle text-success"></i>
    <p class="mb-0 w-100 pl-5">Activity Edited Successfully</p>
  </div>
);
export const failureEditToaster = ({ closeToast, toastProps }) => (
  <div className="d-flex align-items-center">
    <i class="fa fa-2x fa-times text-danger"></i>
    <p class="mb-0 w-100 pl-5">Activity Edit Failed</p>
  </div>
);
export const successDeleteToaster = ({ closeToast, toastProps }) => (
  <div className="d-flex align-items-center">
    <i class="fa fa-2x fa-check-circle text-success"></i>
    <p class="mb-0 w-100 pl-5">Activity Deleted Successfully</p>
  </div>
);
export const failureDeleteToaster = ({ closeToast, toastProps }) => (
  <div className="d-flex align-items-center">
    <i class="fa fa-2x fa-times text-danger"></i>
    <p class="mb-0 w-100 pl-5">Activity Deletion Failed</p>
  </div>
);
export const successMultiDeleteToaster = ({ closeToast, toastProps }) => (
  <div className="d-flex align-items-center">
    <i class="fa fa-2x fa-check-circle text-success"></i>
    <p class="mb-0 w-100 pl-5">Activities Deleted Successfully</p>
  </div>
);
export const failureMultiDeleteToaster = ({ closeToast, toastProps }) => (
  <div className="d-flex align-items-center">
    <i class="fa fa-2x fa-times text-danger"></i>
    <p class="mb-0 w-100 pl-5">Activities Deletion Failed, Please Try Again</p>
  </div>
);
