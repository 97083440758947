/** @format */

import React, { useMemo, useLayoutEffect, useState } from "react";
import DOMPurify from "dompurify";
import { Form } from "react-bootstrap";

function EditActivityModalOrgNameSwapper({
  editModalRowData,
  setEditModalRowData,
  inventory,
  orgNameSelect,
}) {
  const purify = new DOMPurify();

  const [toggleSelectOrg, setToggleSelectOrg] = useState(false);
  const organizationToggler = () => {
    window.$(".edit-orgName-select").selectpicker("destroy");
    setToggleSelectOrg((prevState) => !prevState);
  };

  useLayoutEffect(() => {
    if (inventory.inventoryData) {
      window.$(".edit-orgName-select").selectpicker({
        dropupAuto: false,
        size: 8,
        styleBase: "form-control",
        style: "",
      });
    } else {
      setTimeout(() => {
        window.$(".edit-orgName-select").selectpicker("destroy");
      }, 150);
    }
  }, [inventory.inventoryData, toggleSelectOrg]);

  const renderBasedOnInventory = useMemo(() => {
    if (
      inventory.inventoryData &&
      inventory.inventoryData.length > 0 &&
      toggleSelectOrg
    ) {
      return (
        <Form.Group className="mb-3">
          <Form.Label>
            Organization<span className="text-danger">*</span>
          </Form.Label>
          <select
            title="Select Organization"
            className="edit-orgName-select form-control bootstrap-select selectpicker"
            ref={orgNameSelect}
            required
            minLength="1"
            name="type"
            onChange={() => {
              setEditModalRowData((state) => ({
                ...state,
                type: window.$(orgNameSelect.current).selectpicker("val"),
              }));
            }}
          >
            {inventory.inventoryData.map((option) => (
              <option key={option.site_id} value={option.site_name.trim()}>
                {option.site_name}
              </option>
            ))}
          </select>
          {/* <p className="valid-feedback">Looks good!</p> */}
          <p className="invalid-feedback">Interaction type is required</p>
          <button
            className="btn btn-link p-0 mt-1"
            onClick={() => organizationToggler()}
          >
            Enter Organization Name
          </button>
        </Form.Group>
      );
    } else {
      return (
        <Form.Group className="mb-3">
          <Form.Label>
            Organization<span className="text-danger">*</span>
          </Form.Label>
          <Form.Control
            name="type"
            type="text"
            value={editModalRowData.type}
            onChange={(e) => {
              const sanitizedType = purify.sanitize(e.target.value);
              setEditModalRowData((state) => ({
                ...state,
                type: sanitizedType,
              }));
            }}
            required
          />
          {/* <p className="valid-feedback">Great!</p>
          <p className="invalid-feedback">Organization name is required</p> */}

          <button
            className="btn btn-link p-0 mt-1"
            type="button"
            onClick={() => organizationToggler(true)}
          >
            Search My Organizations
          </button>
        </Form.Group>
      );
    }
  }, [
    inventory,
    editModalRowData.type,
    setEditModalRowData,
    orgNameSelect,
    toggleSelectOrg,
    purify,
  ]);

  return renderBasedOnInventory;
}

export default EditActivityModalOrgNameSwapper;
