/** @format */

import {
  DELETE_MULTI_REQUEST,
  DELETE_MULTI_SUCCESS,
  DELETE_MULTI_FAILURE,
  HANDLE_SELECTED_ROWS,
  RESET_TABLE,
  CLEAR_SELECTED_ROWS,
} from "./DeleteMultiTypes";
import { deleteNote } from "../../API/Api";

export const deleteMultiRequest = () => {
  return {
    type: DELETE_MULTI_REQUEST,
  };
};

export const deleteMultiSuccess = () => {
  return {
    type: DELETE_MULTI_SUCCESS,
  };
};

export const deleteMultiFailure = (response) => {
  return {
    type: DELETE_MULTI_FAILURE,
    payload: response,
  };
};
export const handleSelectedRows = (payload) => {
  return {
    type: HANDLE_SELECTED_ROWS,
    payload: payload,
  };
};

export const resetTable = () => {
  return {
    type: RESET_TABLE,
  };
};
export const clearSelectedRows = () => {
  return {
    type: CLEAR_SELECTED_ROWS,
  };
};

export const deleteMulti = ({ selectedRows, token }) => {
  return async (dispatch) => {
    try {
      dispatch(deleteMultiRequest());
      const mappedRequestsForDelete = selectedRows.map((row) =>
        deleteNote({ id: row.id, company_id: row.companyId, token: token })
      );
      const resolvedPromises = await Promise.all(mappedRequestsForDelete);
      if (resolvedPromises.every((response) => response.status === 1)) {
        dispatch(deleteMultiSuccess());
        return true;
      } else {
        throw new Error("Failed to delete one or more notes");
      }
    } catch (error) {
      dispatch(deleteMultiFailure(error.message));
      return false;
    }
  };
};
// export const mockAPIFetchInventory = () => {
//   return (dispatch) => {
//     dispatch(fetchInventoryData());
//     getUserInventory()
//       .then((res) => {
//         dispatch(fetchInventorySuccess(res));
//       })
//       .catch((err) => dispatch(fetchInventoryFailure(err)));
//   };
// };
