/** @format */

import { handleResponse, handleError } from "./apiUtils";

export function submitNote({ note, token, company_id }) {
  console.log(note);
  return (
    fetch(process.env.REACT_APP_BACKEND_URL + "/submit", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        note,
        company_id,
      }),
    })
      // .then(res=>res.json())
      // .then(data=>console.log(data))
      .then(handleResponse)
      .catch(handleError)
  );
}
export function editNote({ payload, id, token }) {
  return (
    fetch(process.env.REACT_APP_BACKEND_URL + `/notes/${id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    })
      // .then(res=>res.json())
      // .then(data=>console.log(data))
      .then(handleResponse)
      .catch(handleError)
  );
}

export function getNote(param, token) {
  return (
    fetch(process.env.REACT_APP_BACKEND_URL + "/getnotes", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },

      body: JSON.stringify({
        company_id: param,
      }),
    })
      // .then(res=>res.json())
      // .then(data=>console.log(data))
      .then(handleResponse)
      .catch(handleError)
  );
}

export function deleteNote({ id, token, company_id }) {
  return fetch(
    process.env.REACT_APP_BACKEND_URL + `/notes/${id}?company_id=${company_id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then(handleResponse)
    .catch(handleError);
}

export function grabUserInventory(userID, hash, token) {
  return (
    fetch(process.env.REACT_APP_BACKEND_URL + "/grabUserinventory", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        userID: userID,
        hash: hash,
      }),
    })
      // .then(res=>res.json())
      // .then(data=>console.log(data))
      .then(handleResponse)
      .catch(handleError)
  );
}

export function getAllOrgs(token) {
  return (
    fetch(process.env.REACT_APP_BACKEND_URL + "/getAllOrgs", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      // .then(res=>res.json())
      // .then(data=>console.log(data))
      .then(handleResponse)
      .catch(handleError)
  );
}

/**
 * 
 MONGO DB
 */

export function getOrgs({ input, token }) {
  return (
    fetch(process.env.REACT_APP_BACKEND_URL + "/getorgs", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },

      //make sure to serialize your JSON body
      body: JSON.stringify({
        input: input,
        // hash: hash,
      }),
    })
      // .then(res=>res.json())
      // .then(data=>console.log(data))
      .then(handleResponse)
      .catch(handleError)
  );
}
