/** @format */

import React, { useCallback } from "react";
import { connect } from "react-redux";
import {
  deleteMulti,
  resetTable,
} from "../../redux/DeleteMulti/DeleteMultiActions";
import { fetchActivities } from "../../redux/FetchActivities/FetchActivitiesActions";
import {
  expandAll,
  collapseAll,
} from "../../redux/FetchActivities/FetchActivitiesActions";
import DownloadButton from "../download-button";
import RedirectButton from "../redirect-button";
import { toast } from "react-toastify";
import {
  failureMultiDeleteToaster,
  successMultiDeleteToaster,
} from "../modal-toasters";

/**
 *
 * @param {array} activities
 *
 *
 * This component sits below DatatableFilter component.
 */

export const ActionToolbar = ({
  activities,
  expandAllButton,
  collapseAllButton,
  deleteMulti,
  deleteMultiRequest,
  fetchActivities,
  user,
  resetTable,
}) => {
  const handleDeleteMulti = useCallback(async () => {
    const deleteMultiResponse = await deleteMultiRequest({
      selectedRows: deleteMulti.selectedRows,
      token: user.jtToken,
    });
    if (deleteMultiResponse) {
      toast(successMultiDeleteToaster, {
        position: toast.POSITION.TOP_CENTER,
      });
      if (user.company.companyId) {
        const userActivities = await fetchActivities(
          user.company.companyId,
          user.jtToken
        );
        if (userActivities) resetTable();
      }
    } else {
      toast(failureMultiDeleteToaster, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }, [
    deleteMulti.selectedRows,
    deleteMultiRequest,
    fetchActivities,
    user.company,
    user.jtToken,
    resetTable,
  ]);
  return (
    <>
      <div
        className="col p-2 d-flex flex-column flex-md-row align-items-sm-center justify-content-md-between"
        style={{ backgroundColor: "#D5E7F8" }}
      >
        <div>
          <span className="ml-2">
            Activities found:
            <span className="ml-1 ">
              {activities.companyActivities &&
              activities.companyActivities.length
                ? activities.companyActivities.length
                : 0}
            </span>
          </span>
          {activities.expanded ? (
            <button
              onClick={collapseAllButton}
              className="btn text-primary pl-1 "
              id="expand-all-btn"
            >
              | Collapse All
            </button>
          ) : (
            <button
              onClick={expandAllButton}
              className="btn text-primary pl-1 "
              id="expand-all-btn"
            >
              | Expand All
            </button>
          )}
          {deleteMulti.selectedRows.length > 0 ? (
            <button className="btn" onClick={() => handleDeleteMulti()}>
              <i className="fal fa-trash text-primary"></i>
              <span className="ml-1 text-primary">Delete</span>
            </button>
          ) : null}
        </div>
        <div>
          <DownloadButton activities={activities} />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    activities: state.activities,
    deleteMulti: state.deleteMulti,
    user: state.user.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    expandAllButton: () => dispatch(expandAll()),
    collapseAllButton: () => dispatch(collapseAll()),
    deleteMultiRequest: (payload, token) =>
      dispatch(deleteMulti(payload, token)),
    fetchActivities: (company_id, token) =>
      dispatch(fetchActivities(company_id, token)),
    resetTable: () => dispatch(resetTable()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionToolbar);
