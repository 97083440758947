import { useState } from "react";
import DOMPurify from "dompurify";
import mammoth from "mammoth";
import { Button, Modal } from "react-bootstrap";

const FileUploadModal = ({
  updateActivityModalBody,
  closeFileUploadModal,
  showFileUploadModal,
}) => {
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("Select Your File");

  const purify = new DOMPurify();

  const formatHtmlForBody = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const docHtmlChildNodes = [...doc.body.childNodes];
    let olCounter = 0; // ordered list counter

    const convertedHtmlToText = docHtmlChildNodes
      .map((node) => {
        if (node.tagName === "UL") {
          const listItems = [...node.querySelectorAll("li")];
          const formattedList = listItems
            .map((li) => `• ${li.textContent}`)
            .join("\n");
          return formattedList;
        } else if (node.tagName === "OL") {
          const previousNode = node.previousElementSibling;
          if (previousNode && previousNode.tagName === "P") {
            olCounter = 0;
          }

          const listItems = [...node.querySelectorAll("li")];
          const formattedList = listItems
            .map((li) => {
              olCounter++;
              return `${olCounter}. ${li.textContent}`;
            })
            .join("\n");

          return formattedList;
        } else {
          return node.textContent;
        }
      })
      .join("\n"); // Add single newline between sections

    return convertedHtmlToText;
  };

  const handleFileOnchange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      setFile(selectedFile);
      setFileName(e.target.files[0].name);
    }
  };

  const handleUploadedFile = () => {
    const textFile = ".txt";
    const wordDoc = "docx" || "doc";

    if (file) {
      if (file.name.includes(textFile)) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const fileContent = e.target.result;
          const sanitizedTxtFileData = purify.sanitize(fileContent);

          updateActivityModalBody(sanitizedTxtFileData);
          closeFileUploadModal();
        };
        reader.readAsText(file);
      } else if (file.name.includes(wordDoc)) {
        const reader = new FileReader();

        reader.onload = async (e) => {
          try {
            const result = await mammoth.convertToHtml({
              arrayBuffer: e.target.result,
            });
            const extractedHtml = result.value;

            if (extractedHtml.includes("ul")) {
              const sanitizedHtml = purify.sanitize(extractedHtml);
              const formattedTextForBody = formatHtmlForBody(sanitizedHtml);

              updateActivityModalBody(formattedTextForBody);
            } else {
              const result = await mammoth.extractRawText({
                arrayBuffer: e.target.result,
              });
              const extractedText = result.value;
              const sanitizedText = purify.sanitize(extractedText);

              updateActivityModalBody(sanitizedText);
            }

            closeFileUploadModal();
          } catch (error) {
            console.error("Error extracting text from .docx or .doc", error);
          }
        };

        reader.readAsArrayBuffer(file);
      }
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showFileUploadModal}
        size="md"
        onHide={() => {
          closeFileUploadModal();
        }}
        id="fileUploadModal"
      >
        <Modal.Header>
          <Modal.Title>Upload Your Notes</Modal.Title>
          <button
            type="button"
            className="close opacity-10"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeFileUploadModal}
          >
            <span aria-hidden="true">
              <i class="fal fa-times text-15 text-dark-fill"></i>
            </span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="custom-file mb-4">
            <input
              type="file"
              className="custom-file-input"
              id="customFile"
              onChange={handleFileOnchange}
              accept=".txt, .docx"
            />
            <label className="custom-file-label" htmlFor="customFile">
              {fileName}
            </label>
          </div>
          <span style={{ fontSize: "14px" }}>
            * Please upload text or word documents. Other file types are not
            currently supported.
          </span>
          <Button
            className="btn btn-primary btn-block mt-4"
            onClick={handleUploadedFile}
            type="submit"
          >
            Upload
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FileUploadModal;
