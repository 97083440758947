/** @format */
import {
  FETCH_ACTIVITIES_REQUEST,
  FETCH_ACTIVITIES_SUCCESS,
  FETCH_ACTIVITIES_FAILURE,
  EXPAND_ALL_ACTIVITIES,
  COLLAPSE_ALL_ACTIVITIES,
} from "./FetchActivitiesTypes";

const initialState = {
  loading: false,
  companyActivities: null,
  expanded: false,
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ACTIVITIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ACTIVITIES_SUCCESS:
      return {
        loading: false,
        companyActivities: action.payload,
        error: "",
      };
    case FETCH_ACTIVITIES_FAILURE:
      return {
        loading: false,
        companyActivities: [],
        error: action.payload,
      };
    case EXPAND_ALL_ACTIVITIES:
      return {
        ...state,
        companyActivities: state.companyActivities.map((item) => ({
          ...item,
          expandAll: true,
        })),
        expanded: true,
      };
    case COLLAPSE_ALL_ACTIVITIES:
      return {
        ...state,
        companyActivities: state.companyActivities.map((item) => ({
          ...item,
          expandAll: false,
        })),
        expanded: false,
      };
    default:
      return state;
  }
};

export default reducer;
