/** @format */

import { combineReducers } from "redux";
import JwtToken from "./redux/jwtToken/userTokenReducer";
import FetchActivitiesReducer from "./redux/FetchActivities/FetchActivitiesReducer";
import AddActivityReducer from "./redux/AddActivities/AddActivitiesReducer";
import AddActivityModalToggler from "./redux/Toggler/AddActivityModal/AddActivityModalReducer";

import DataTableFilterReducer from "./redux/DataTableFilter/DataTabeFilterReducer";
import DeleteMultiReducer from "./redux/DeleteMulti/DeleteMultiReducer";
import FetchUserInventoryReducer from "./redux/FetchUserInventory/FetchUserInventoryReducer";
import EditModalSelectedRow from "./redux/EditOrganization/SelectedRowForEditReducer";

const rootReducer = combineReducers({
  user: JwtToken,
  activities: FetchActivitiesReducer,
  pendingActivity: AddActivityReducer,
  addActivityToggleState: AddActivityModalToggler,
  editModalData: EditModalSelectedRow,
  filterUserInput: DataTableFilterReducer,
  deleteMulti: DeleteMultiReducer,
  userInventory: FetchUserInventoryReducer,
});

export default rootReducer;
