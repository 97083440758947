/** @format */

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { login } from "../../redux/jwtToken/userTokenActions";
import { useHistory } from "react-router-dom";
import cookie from "js-cookie";
import HomePage from "../home-page";

const Validate = ({ loginAction, logoutAction }) => {
  useEffect(() => {
    logoutAction();
  }, []);

  let history = useHistory();
  let { hash } = useParams();

  const [loading, setLoading] = useState(true);

  // validate token from cookies or query string. if not valid
  // or not found, redirect to JobTarget Login otherwise store to cookies

  const cookie_jtToken = cookie.get("jtToken");
  const jtToken = cookie_jtToken ? cookie_jtToken : hash;

  let navPoll;

  function pingNav() {
    console.log("running");
    window.dispatchEvent(new CustomEvent("requestuserinfo:jt:navbar"));
  }

  const checkAppPermission = (data) => {
    console.log("checking user app data");
    const permission = data.find((permission) => {
      return (
        permission.app_id &&
        permission.app_id.toString() === process.env.REACT_APP_APP_PERMISSION
      );
    });
    if (permission && permission.status === "Active") {
      return true;
    }
    return false;
  };

  async function handleNavData(event) {
    console.log("nav response...");
    const permission = checkAppPermission(event.detail.appPermissions);
    if (permission) {
      console.log("app permission granted");
      loginAction({ user: event.detail, jtToken: jtToken });
      clearInterval(navPoll);
      setLoading(false);
    } else {
      console.log("app permission denied");
      window.location.href = process.env.REACT_APP_LOGIN_URL;
    }
  }

  useEffect(() => {
    window.addEventListener("userinfo:jt:navbar", handleNavData);
    navPoll = setInterval(pingNav, 250);
    return () => {
      window.removeEventListener("userinfo:jt:navbar", handleNavData);
      clearInterval(navPoll);
    };
  }, []);

  useEffect(() => {
    if (!loading) {
      history.push(`/?jtToken=${jtToken}`);
    }
  }, [jtToken, loading, history]);

  return (
    <>
      {loading ? (
        <div className="col d-flex flex-column justify-content-center h-100 align-items-center">
          <div
            className="spinner-border text-primary h-6 w-6"
            role="status"
          ></div>
          <h4 className="mt-3" style={{ color: "#7e8299" }}>
            Loading Activities
          </h4>
        </div>
      ) : (
        <HomePage />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginAction: (token) => dispatch(login(token)),
    logoutAction: () => dispatch({ type: "USER_LOGOUT" }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Validate);
