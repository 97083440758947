/** @format */

import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import { composeWithDevTools } from "redux-devtools-extension";
import logger from "redux-logger";
import rootReducer from "./rootReducer";

const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });

const localStorageMiddleware = ({ getState }) => {
  // <--- FOCUS HERE
  return (next) => (action) => {
    const result = next(action);
    localStorage.setItem("applicationState", JSON.stringify(getState()));
    return result;
  };
};

console.log("initializing store");

const reHydrateStore = () => {
  // <-- FOCUS HERE

  if (localStorage.getItem("applicationState") !== null) {
    return JSON.parse(localStorage.getItem("applicationState")); // re-hydrate the store
  }
};

let store;

if (process.env.REACT_APP_NODE_ENV === "development") {
  store = createStore(
    rootReducer,
    reHydrateStore(),
    composeEnhancers(
      applyMiddleware(
        reduxImmutableStateInvariant(),
        // localStorageMiddleware,
        logger,
        thunk
      )
    )
  );
} else {
  store = createStore(
    rootReducer,
    reHydrateStore(),
    composeEnhancers(
      applyMiddleware(
        reduxImmutableStateInvariant(),
        // localStorageMiddleware,
        thunk
      )
    )
  );
}

// const unsubscribe = store.subscribe(() => store.getState());
// unsubscribe();
export default store;
