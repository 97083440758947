/** @format */
import {
  ADD_ACTIVITIES_REQUEST,
  ADD_ACTIVITIES_SUCCESS,
  ADD_ACTIVITIES_FAILURE,
} from "./AddActivitiesTypes";

const initialState = {
  loading: false,
  companyActivities: null,
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ACTIVITIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_ACTIVITIES_SUCCESS:
      return {
        loading: false,
        companyActivities: action.payload,
        error: "",
      };
    case ADD_ACTIVITIES_FAILURE:
      return {
        loading: false,
        companyActivities: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
