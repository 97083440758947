/** @format */
import {
  DELETE_MULTI_REQUEST,
  DELETE_MULTI_SUCCESS,
  DELETE_MULTI_FAILURE,
  HANDLE_SELECTED_ROWS,
  RESET_TABLE,
  CLEAR_SELECTED_ROWS,
} from "./DeleteMultiTypes";

const initialState = {
  clearSelectedRows: false,
  selectedRows: [],
  loading: false,
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_MULTI_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_MULTI_SUCCESS:
      return {
        loading: false,
        selectedRows: [],
        error: "",
        clearSelectedRows: true,
      };
    case DELETE_MULTI_FAILURE:
      return {
        loading: false,
        selectedRows: [],
        error: action.payload,
        clearSelectedRows: false,
      };
    case HANDLE_SELECTED_ROWS:
      return {
        ...state,
        selectedRows: action.payload,
      };

    //This will bring the clearSelectedRows value back to it's original state of false. This will allow the next event to switch it to true and trigger the clearSelectedRows API in Datatable//
    case RESET_TABLE:
      return {
        ...state,
        clearSelectedRows: false,
      };
    //This will turn the clearSelectedRows variable to drew which will fire the clearSelectedRows API in Datatable to clear selected rows cache.//
    case CLEAR_SELECTED_ROWS:
      return {
        ...state,
        clearSelectedRows: true,
      };
    default:
      return state;
  }
};

export default reducer;
