/** @format */

import React from "react";
import OrganizationNameSelect from "./org-name-select";
import OrganizationNameInput from "./org-name-input";

function ActivityModalOrgNameSwapper({
  setActivityModal,
  inventory,
  orgNameSelect,
  setSelectFromInventory,
  selectFromInventory,
  activityModal,
}) {
  if (
    inventory.inventoryData &&
    inventory.inventoryData.length > 0 &&
    selectFromInventory
  ) {
    return (
      <OrganizationNameSelect
        activityModal={activityModal}
        inventory={inventory}
        setActivityModal={setActivityModal}
        orgNameSelect={orgNameSelect}
        setSelectFromInventory={setSelectFromInventory}
      />
    );
  } else {
    return (
      <OrganizationNameInput
        activityModal={activityModal}
        setActivityModal={setActivityModal}
        setSelectFromInventory={setSelectFromInventory}
        activityModal={activityModal}
      />
    );
  }
}

export default ActivityModalOrgNameSwapper;
