/** @format */
import {
  HANDLE_FLUSH_ROW_DATA,
  HANDLE_SELECT_ROW_DATA,
} from "./SelectedRowForEditTypes";

const initialState = {
  categories: [],
  interaction: [],
  type: "",
  location: "",
  title: "",
  contentBody: "",
  company_id: null,
  author: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_SELECT_ROW_DATA:
      return {
        ...action.payload,
      };
    case HANDLE_FLUSH_ROW_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
