/** @format */

import React, { useEffect } from "react";
import { connect } from "react-redux";
import Title from "../title";
import Filter from "../table-filter";
import ActionToolbar from "../subheader";
import ActivityTable from "../table";
import { useHistory } from "react-router-dom";
import { login } from "../../redux/jwtToken/userTokenActions";
import { fetchActivities } from "../../redux/FetchActivities/FetchActivitiesActions";
import { fetchInventory } from "../../redux/FetchUserInventory/FetchUserInventoryActions";
import { toggleAddActivityModal } from "../../redux/Toggler/AddActivityModal/AddActivityModalActions";
import { filterInput } from "../../redux/DataTableFilter/DataTableFilterActions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const HomePage = ({
  user,
  fetchActivities,
  toggleAddActivityModal,
  filterInput,
  fetchInventory,
}) => {
  // Initializes data from APIs and Shared Navbar event
  const history = useHistory();

  useEffect(() => {
    if (!user.user.jtToken) {
      console.log("No token found");
      history.push("/");
    }
  }, []);

  useEffect(() => {
    if (user.user && user.user.company.companyId) {
      console.log('fetching activities')
      fetchActivities(user.user.company.companyId, user.user.jtToken);
    }
  }, [user.user, fetchActivities]);


  useEffect(() => {
    if (user.user && user.user.user_id) {
      fetchInventory(user.user.user_id, user.user.company.companyId, user.user.jtToken);
    }
  }, [user.user, fetchInventory]);

  return (
    <>
      <Title toggleAddActivityModal={toggleAddActivityModal} />
      <Filter filterInput={filterInput} />
      <div className="card-body pt-2" id="jt-app-body">
        <div className="row">
          <div className="col-12">
            <ActionToolbar />
            <ActivityTable />
          </div>
        </div>
        <ToastContainer autoClose={3500} pauseOnHover />
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
    activities: state.activities,
    inventory: state.userInventory,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginAction: (token) => dispatch(login(token)),
    fetchActivities: (company_id, token) =>
      dispatch(fetchActivities(company_id, token)),
    fetchInventory: (user_id, company_id, token) =>
      dispatch(fetchInventory(user_id, company_id, token)),
    toggleAddActivityModal: () => dispatch(toggleAddActivityModal()),
    filterInput: (input) => dispatch(filterInput(input)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
