/** @format */

import {
  HANDLE_FLUSH_ROW_DATA,
  HANDLE_SELECT_ROW_DATA,
} from "./SelectedRowForEditTypes";

export const handleSelectRowData = (row) => {
  return {
    type: HANDLE_SELECT_ROW_DATA,
    payload: row,
  };
};
export const handleFlushSelectRowData = () => {
  return {
    type: HANDLE_FLUSH_ROW_DATA,
  };
};
