/** @format */

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";

const wrapHOC = (WrappedComponent) => {
  class Wrapper extends React.PureComponent {
    render() {
      return (
        <div className="container my-1" id="jt-app">
          <div className="row">
            <div className="col-12">
              <div className="card border-0 w-100 h-100" id="jt-app-card">
                <WrappedComponent {...this.props} />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
  return Wrapper;
};

const WrappedApp = wrapHOC(App);

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <WrappedApp />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
