/** @format */

import { HANDLE_FILTER_INPUT } from "./DataTableFilterTypes";

export const filterInput = (input) => {
  return {
    type: HANDLE_FILTER_INPUT,
    payload: input,
  };
};
