/** @format */

import React from "react";
import { Form } from "react-bootstrap";

function OrganizationNameSelect({
  orgNameSelect,
  setActivityModal,
  inventory,
  setSelectFromInventory,
  activityModal,
}) {
  const findDiversity = (option) =>
    inventory.inventoryData.find(
      (record) => record.site_name.toLowerCase() === option.toLowerCase()
    );
  return (
    <Form.Group className="mb-3">
      <Form.Label>
        Organization<span className="text-danger">*</span>
      </Form.Label>
      <select
        title="Select Organization"
        className="orgNameSelect form-control bootstrap-select selectpicker"
        ref={orgNameSelect}
        required
        name="type"
        onChange={(e) => {
          const diversity = findDiversity(e.target.value);
          setActivityModal({
            ...activityModal,
            organizationName: window.$(e.target).selectpicker("val"),
            diversityCategories: diversity.ans,
            location: `${diversity.City}, ${diversity.State}`,
          });
        }}
      >
        {inventory.inventoryData.map((option, i) => (
          <option key={i} value={option.site_name} data={option.ans}>
            {option.site_name}
          </option>
        ))}
      </select>
      <button
        className="btn btn-link p-0 mt-1"
        onClick={() => setSelectFromInventory(false)}
      >
        Enter Organization Name
      </button>
    </Form.Group>
  );
}

export default OrganizationNameSelect;
