/** @format */

/**
 * SELECTED ROWS HANDLER
 */

export const HANDLE_SELECTED_ROWS = "HANDLE_SELECTED_ROWS";
export const RESET_TABLE = "RESET_TABLE";
export const CLEAR_SELECTED_ROWS = "CLEAR_SELECTED_ROWS";

/**
 * REQUEST TO DELETE MULTI ROWS
 */
export const DELETE_MULTI_REQUEST = "DELETE_MULTI_REQUEST";
export const DELETE_MULTI_SUCCESS = "DELETE_MULTI_SUCCESS";
export const DELETE_MULTI_FAILURE = "DELETE_MULTI_FAILURE";
