/** @format */
import axios from "axios";
import { LOADING, LOGIN, LOGOUT, VALIDATED_USER_ID } from "./userTokenTypes";
import cookie from "js-cookie";

export const loading = () => {
  return {
    type: LOADING,
  };
};

export const login = (response) => {
  return {
    type: LOGIN,
    payload: response,
  };
};

export const logout = () => {
  return {
    type: LOGOUT,
  };
};

export const validatedUserId = (response) => {
  return {
    type: VALIDATED_USER_ID,
    payload: response,
  };
};

export const logoutAction = () => {
  return async (dispatch) => {
    try {
      dispatch(logout());
    } catch (error) {}
  };
};
