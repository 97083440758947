/** @format */
import { TOGGLE_ADD_ACTIVITY_MODAL } from "./AddActivityModalTypes";

const initialState = {
  toggled: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_ADD_ACTIVITY_MODAL:
      return {
        toggled: !state.toggled,
      };
    default:
      return state;
  }
};

export default reducer;
