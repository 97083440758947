/** @format */
import { HANDLE_FILTER_INPUT } from "./DataTableFilterTypes";

const initialState = {
  input: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_FILTER_INPUT:
      return {
        input: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
