/** @format */
import React, { useMemo, useState } from "react";

import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import ActivityModal from "../add-activity-modal";
import ConfirmDelete from "../confirm-delete";
import CustomPagination from "../pagination";
import EditActivityModal from "../edit-activity-modal/index";
import FileUploadModal from "../file-upload-modal";
import {
  resetTable,
  clearSelectedRows,
} from "../../redux/DeleteMulti/DeleteMultiActions";
import { toggleAddActivityModal } from "../../redux/Toggler/AddActivityModal/AddActivityModalActions";
import { handleSelectedRows } from "../../redux/DeleteMulti/DeleteMultiActions";
import { deleteNote } from "../../API/Api";
import { formatDate } from "../../tools/format-date";
import { fetchActivities } from "../../redux/FetchActivities/FetchActivitiesActions";
import { handleSelectRowData } from "../../redux/EditOrganization/SelectedRowForEditActions";
import GenerateCollapsibleCategories from "../collapsible-categories";
import GenerateCollapsibleInteractions from "../collapsible-interactions";

/**
 *
 * @param {array} inventory
 * @param {function} updateKnownValues
 *
 * This component is responsible for initializing the activities Datatables.  This Datatable allows the user to add an activity in the "Actions" column. When adding an activity, the user will interface with the Add Activity Modal.
 */

const customStyles = {
  rows: {
    style: {
      paddingBottom: "1rem",
      paddingTop: "1rem",
    },
  },
};
const ExpandedComponent = ({ data }) => (
  <div
    className="container p-4 text-break"
    style={{ background: "rgba(47, 134, 221, 0.08)" }}
  >
    <h4 className="">{data.title}</h4>
    <hr />
    <div className="pt-2">
      <strong className="pr-2">Details:</strong>
      {formatContentBody(data.contentBody)}
    </div>
  </div>
);

const formatContentBody = (contentBody) => {
  // Split the content into sections
  const sections = contentBody.split(/\n{2,}/);

  const formattedContent = sections.map((section, i) => {
    const hasSpacing = /\n/.test(section);

    return (
      <div key={i}>
        {hasSpacing ? (
          section.split(/\r?\n/).map((line, j) => <div key={j}>{line}</div>)
        ) : (
          <div>{section}</div>
        )}
      </div>
    );
  });

  return formattedContent;
};

const selectableCheckboxComponent = React.forwardRef((props, ref) => {
  return (
    <div className="form-check form-check-inline ml-0">
      <label className="form-check-label ">
        <input
          {...props}
          className="form-check-input"
          type="checkbox"
          value=""
        />
        <span id="selectableRowCheckbox"></span>
      </label>
    </div>
  );
});

function ActivityTable({
  activities,
  user,
  fetchActivities,
  addActivityToggleState,
  toggleAddActivityModal,
  userInput,
  handleSelectedRows,
  deleteMulti,
  resetTable,
  clearSelectedRows,
  handleEditSelectRowData,
}) {
  const [activityModal, setActivityModal] = useState({
    organizationName: "",
    diversityCategories: [],
    interactionCategories: [],
    location: "",
    title: "",
    body: "",
    author: "",
    company_id: null,
    numberOfHires: 0,
    numberOfReferals: 0,
    effectiveness: "",
  });
  const [deleteRowId, setDeleteRowId] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);

  const deleteMultiSelectedRows = ({ selectedRows }) => {
    try {
      const selectedRowIds = selectedRows.map((obj) => ({
        id: obj._id,
        companyId: user.company.companyId,
      }));
      handleSelectedRows(selectedRowIds);
    } catch (error) {
      console.error("An error occurred while processing selectedRows:", error);
    }
  };

  const filteredActivities = useMemo(() => {
    try {
      return activities.companyActivities.filter((item) => {
        let date = formatDate(item.createdAt);
        let formattedItem = { ...item, createdAt: date };
        return (
          (formattedItem.type &&
            formattedItem.type
              .toLowerCase()
              .includes(userInput.toLowerCase())) ||
          (formattedItem.categories.toString() &&
            formattedItem.categories
              .toString()
              .toLowerCase()
              .includes(userInput.toLowerCase())) ||
          (formattedItem.interaction.toString() &&
            formattedItem.interaction
              .toString()
              .toLowerCase()
              .includes(userInput.toLowerCase())) ||
          (formattedItem.title &&
            formattedItem.title
              .toLowerCase()
              .includes(userInput.toLowerCase())) ||
          (formattedItem.author &&
            formattedItem.author
              .toLowerCase()
              .includes(userInput.toLowerCase())) ||
          (formattedItem.contentBody &&
            formattedItem.contentBody
              .toLowerCase()
              .includes(userInput.toLowerCase())) ||
          (formattedItem.location &&
            formattedItem.location
              .toLowerCase()
              .includes(userInput.toLowerCase())) ||
          (formattedItem.createdAt &&
            formattedItem.createdAt
              .toLowerCase()
              .includes(userInput.toLowerCase()))
        );
      });
    } catch (error) {
      return "";
    }
  }, [activities.companyActivities, userInput]);

  function showConfirmDelete({ id }) {
    // Set the rowId from the table to be passed to the delete API. It will also toggle the confirm-delete modal
    setDeleteRowId(id);
    setShowDeleteConfirm((prevState) => !prevState);
  }

  function toggleEditModal(row) {
    //Toggle the edit modal and pass it the necessary row values
    const {
      categories,
      company_id,
      author,
      interaction,
      title,
      contentBody,
      location,
      type,
      effectiveness,
      numberOfReferals,
      numberOfHires,
      _id,
    } = row;
    handleEditSelectRowData({
      categories,
      interaction,
      type,
      location,
      title,
      contentBody,
      company_id,
      author,
      effectiveness,
      numberOfReferals,
      numberOfHires,
      _id,
    });
    setShowEditModal((prevState) => !prevState);
  }

  const closeFileUploadModal = () => {
    setShowFileUploadModal(false);
  };

  const openFileUploadModal = () => {
    setShowFileUploadModal(true);
  };

  const updateActivityModalBody = (fileContent) => {
    setActivityModal({
      ...activityModal,
      body: fileContent,
    });
  };

  return (
    <>
      <DataTable
        columns={[
          {
            name: "Organization",
            selector: "type",
            sortable: true,
            wrap: true,
          },
          {
            name: "Diversity",
            selector: (obj, i) => {
              if (obj.categories.length > 1) {
                return <GenerateCollapsibleCategories obj={obj} i={i} />;
              } else return obj.categories;
            },

            sortable: true,
            wrap: true,
          },
          {
            name: "Interaction Type",
            selector: (obj, i) => {
              if (obj.interaction.length > 1) {
                return <GenerateCollapsibleInteractions obj={obj} i={i} />;
              } else return obj.interaction;
            },
            sortable: true,
            wrap: true,
          },
          {
            name: "Referrals",
            selector: "numberOfReferals",
            right: true,
            width: "7%",
            sortable: true,
          },
          {
            name: "Hires",
            selector: "numberOfHires",
            right: true,
            width: "7%",
            sortable: true,
          },

          {
            name: "Location",
            selector: "location",
            sortable: true,
            wrap: true,
            width: "12%",
          },
          {
            name: "Date",
            selector: (row) => formatDate(row.createdAt),
            sortable: true,
            //wrap: true,
          },
          {
            name: "Author",
            selector: "author",
            sortable: true,
            wrap: true,
          },

          {
            width: "5%",
            name: "Actions",
            id: "actions",
            cell: (row) => (
              <i
                className="fal fa-edit fa-lg text-primary"
                aria-label="edit"
                color="secondary"
                onClick={() => toggleEditModal(row)}
              ></i>
            ),
          },
          {
            width: "5%",
            cell: (row) => (
              <i
                className="fal fa-trash fa-lg text-primary"
                aria-label="delete"
                color="secondary"
                onClick={() => showConfirmDelete({ id: row._id })}
              ></i>
            ),
          },
        ]}
        data={filteredActivities}
        selectableRows
        selectableRowsComponent={selectableCheckboxComponent}
        onSelectedRowsChange={deleteMultiSelectedRows}
        clearSelectedRows={deleteMulti.clearSelectedRows}
        pagination
        paginationComponent={CustomPagination}
        disabled={user && user.userType === "Admin" ? false : true}
        highlightOnHover
        pointerOnHover
        responsive
        noHeader={true}
        customStyles={customStyles}
        expandableRows
        expandableRowExpanded={(row) => row.expandAll}
        expandableRowsComponent={
          <ExpandedComponent data={filteredActivities} />
        }
      />
      {!showFileUploadModal && (
        <ActivityModal
          show={addActivityToggleState.toggled}
          onHide={toggleAddActivityModal}
          openFileUploadModal={openFileUploadModal}
          activityModal={activityModal}
          setActivityModal={setActivityModal}
        />
      )}

      {showFileUploadModal && (
        <FileUploadModal
          showFileUploadModal={showFileUploadModal}
          closeFileUploadModal={closeFileUploadModal}
          updateActivityModalBody={updateActivityModalBody}
        />
      )}
      <ConfirmDelete
        show={showDeleteConfirm}
        handleClose={() => setShowDeleteConfirm((prevState) => !prevState)}
        deleteNote={deleteNote}
        fetchActivities={fetchActivities}
        deleteRowId={deleteRowId}
        user={user}
        resetTable={resetTable}
        clearSelectedRows={clearSelectedRows}
      />
      <EditActivityModal
        show={showEditModal}
        onHide={() => setShowEditModal((prevState) => !prevState)}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    activities: state.activities,
    user: state.user.user,
    userInput: state.filterUserInput.input,
    addActivityToggleState: state.addActivityToggleState,
    deleteMulti: state.deleteMulti,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchActivities: (company_id, token) =>
      dispatch(fetchActivities(company_id, token)),
    toggleAddActivityModal: () => dispatch(toggleAddActivityModal()),
    handleSelectedRows: (selected) => dispatch(handleSelectedRows(selected)),
    resetTable: () => dispatch(resetTable()),
    clearSelectedRows: () => dispatch(clearSelectedRows()),
    handleEditSelectRowData: (data) => dispatch(handleSelectRowData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityTable);
